"use client";

import classNames from "classnames/bind";
import { imageBuilder } from "../../sanity/lib/image";
import { ProjectSticker } from "../ProjectSticker/ProjectSticker";
import { InclusivelyHidden } from "../InclusivelyHidden/InclusivelyHidden";
import Link from "next/link";
import styles from "./ProjectGalleryDesktop.module.css";
import { useRef, useState, useEffect } from "react";
// import { useLoadingContext } from "../../LoadingContext";
import { motion, useTransform, useScroll } from "framer-motion";

const cx = classNames.bind(styles);

type Item = {
  imageUrl: string;
  imageAlt?: string;
  stickerText?: string;
  stickerPosition?: "bottom-left" | "bottom-right" | "top-left" | "top-right";
  title: string;
  href: string;
};

const Slide = ({ item }: { item: Item }) => {
  const [tooltipMode, setTooltipMode] = useState<
    /* Follow the cursor around */
    | "cursor"
    /* Hang below the image (primarily for keyboard users) */
    | "below"
  >("below");

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  /* Lazy loading causes issues for items off screen */
  // const loading = useLoadingContext();

  return (
    <div
      className={cx("item")}
      onFocus={() => setTooltipMode("below")}
      onMouseEnter={() => setTooltipMode("cursor")}
      onMouseMove={(event) => {
        const cellElement = event.currentTarget;
        const tooltipElement = tooltipRef.current;

        requestAnimationFrame(() => {
          const cellRect = cellElement.getBoundingClientRect();

          const left = event.clientX - cellRect.left;
          const top = event.clientY - cellRect.top;
          tooltipElement?.style.setProperty("--transform", `translate3d(${left}px, ${top}px, 0)`);
        });
      }}
    >
      <Link className={cx("link")} href={item.href}>
        <InclusivelyHidden as="h3">{item.title}</InclusivelyHidden>
      </Link>
      <img
        className={cx("image")}
        // To be kept in-sync with `width` of `.item` in stylesheet.
        sizes="(min-width: 768px) min(42vw, 740px), 80vw"
        srcSet={[320, 360, 375, 414, 740, 768, 1024, 1280, 1366, 1440, 1480, 1728, 1920]
          .map((width) => {
            const src = imageBuilder.image(item.imageUrl).format("webp").width(width).quality(90);

            return `${src} ${width}w`;
          })
          .join(",\n")}
        src={item.imageUrl}
        // loading={loading}
        alt={item.imageAlt}
      />
      {item.stickerText && (
        <div className={cx("sticker")} data-align={item.stickerPosition ?? "bottom-right"}>
          <ProjectSticker>{item.stickerText}</ProjectSticker>
        </div>
      )}
      <div
        /**
         * Deliberately not marking this up as role=tooltip, because the content
         * is redundant - it's a duplicate of the InclusivelyHidden `h3` content
         * above. This tooltip provides purely aesthetic functionality for
         * sighted users only.
         */
        aria-hidden="true"
        ref={tooltipRef}
        className={cx("tooltip")}
        data-mode={tooltipMode}
      >
        {item.title}
      </div>
    </div>
  );
};

export const ProjectGalleryDesktop = ({ title, items }: { title?: string; items: Item[] }) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [stickyHeight, setStickyHeight] = useState(0);
  const [stickyWidth, setStickyWidth] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [wrapperPadding, setWrapperPadding] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  // TODO: Scroll tracking gets messed up when you change the viewport!
  // Set the start and end positions for the scroller
  const scrollEnd = wrapperHeight - stickyHeight / 2 - viewportHeight / 2;
  const scrollStart = (viewportHeight / 2 - stickyHeight / 2) * -1;
  const totalHorizontalScrollDistance = (stickyWidth - wrapperWidth + wrapperPadding) * -1;

  // Framer Motion scroll target and offsets
  const { scrollYProgress } = useScroll({
    target: wrapperRef,
    offset: [`${scrollStart}px`, `${scrollEnd}px`],
  });

  useEffect(() => {
    const updateDimensions = () => {
      const stickyElement = stickyRef.current;
      const wrapperElement = wrapperRef.current;

      if (stickyElement && wrapperElement) {
        // Get heights/widths of things we are scrolling
        const stickyHeight = stickyElement.offsetHeight;
        const stickyWidth = stickyElement.scrollWidth;
        const wrapperHeight = wrapperElement.offsetHeight;
        const wrapperWidth = wrapperElement.offsetWidth;

        // Get the padding
        const style = window.getComputedStyle(wrapperElement);
        const paddingLeft = parseFloat(style.paddingLeft);
        const paddingRight = parseFloat(style.paddingRight);
        const wrapperPadding = paddingLeft + paddingRight;

        // Get the viewport height
        const viewportHeight = window.innerHeight;

        setStickyHeight(stickyHeight);
        setStickyWidth(stickyWidth);
        setWrapperHeight(wrapperHeight);
        setWrapperWidth(wrapperWidth);
        setWrapperPadding(wrapperPadding);
        setViewportHeight(viewportHeight);
      }
    };

    const resizeObserver = new ResizeObserver(() => updateDimensions());

    if (stickyRef.current) {
      resizeObserver.observe(stickyRef.current);
    }
    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const x = useTransform(scrollYProgress, [0, 1], ["0", `${totalHorizontalScrollDistance}px`]);

  return (
    <div className={cx("full-bleed-container")}>
      <div
        className={cx("container")}
        ref={wrapperRef}
        style={
          {
            "--_js-height-multiplier": items.length,
            "--_js-height-offset": stickyHeight,
          } as React.CSSProperties
        }
      >
        <div className={cx("sticky")} ref={stickyRef}>
          {title && <h2 className={cx("headline")}>{title}</h2>}
          <motion.div style={{ x }} className={cx("scroller")}>
            {items.map((item, index) => (
              <Slide key={index} item={item} />
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
