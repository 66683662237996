"use client";

import { useViewportDimensions } from "../../helpers/useViewportDimensions";
import { ProjectGalleryMobile } from "./ProjectGalleryMobile";
import { ProjectGalleryDesktop } from "./ProjectGalleryDesktop";

type Item = {
  imageUrl: string;
  imageAlt?: string;
  stickerText?: string;
  stickerPosition?: "bottom-left" | "bottom-right" | "top-left" | "top-right";
  title: string;
  href: string;
};

export const ProjectGallery = ({ title, items }: { title?: string; items: Item[] }) => {
  const TABLET_BREAKPOINT = 650;
  const { viewportWidth } = useViewportDimensions();

  return (
    <>
      {viewportWidth >= TABLET_BREAKPOINT ? (
        <ProjectGalleryDesktop items={items} title={title} />
      ) : (
        <ProjectGalleryMobile items={items} title={title} />
      )}
    </>
  );
};
