import createImageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { env } from "../../env";

export const imageBuilder = createImageUrlBuilder({
  projectId: env.NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID,
  dataset: env.NEXT_PUBLIC_SANITY_STUDIO_DATASET,
});

export const urlForImage = (source: SanityImageSource) => {
  return imageBuilder?.image(source).auto("format").fit("max").url();
};
