// TODO: Cookiebot window types?

type ConsentType = "necessary" | "preferences" | "statistics" | "marketing";

// necessary preferences statistics marketing
export const hasCookieConsent = (category: ConsentType): boolean => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (
    typeof window !== "undefined" &&
    (window as any).Cookiebot &&
    (window as any).Cookiebot.consent
  ) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    return (window as any).Cookiebot.consent[category] === true;
  }
  return false;
};
