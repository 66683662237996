"use client";

import classNames from "classnames/bind";
import styles from "./ImageAndTextBlock.module.css";
import { imageBuilder } from "../../sanity/lib/image";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);

const VIEWPORT_WIDTHS = [320, 360, 375, 414, 768, 1024, 1280, 1366, 1440, 1728, 1920];

export const ImageAndTextBlock = ({
  text,
  image,
  layout,
}: {
  text: React.ReactNode;
  image: string;
  layout: "image-text" | "text-image";
}) => {
  const loading = useLoadingContext();
  return (
    <div className={cx("container")} data-layout={layout}>
      <div className={cx("image")}>
        <img
          srcSet={VIEWPORT_WIDTHS.map((width) => {
            const src = imageBuilder.image(image).format("webp").width(width).quality(90);

            return `${src} ${width}w`;
          }).join(",\n")}
          sizes="(min-width: 1330px) min(960px, 50vw), 100vw"
          src={image}
          loading={loading}
          alt=""
        />
      </div>
      <div className={cx("text")}>{text}</div>
    </div>
  );
};
