import classNames from "classnames/bind";
import styles from "./ProjectSticker.module.css";

const cx = classNames.bind(styles);

export const ProjectSticker = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={cx("sticker")}>
      <div className={cx("inner")}>{children}</div>
    </div>
  );
};
