"use client";

import Link from "next/link";
import classNames from "classnames/bind";
import { InclusivelyHidden } from "../InclusivelyHidden/InclusivelyHidden";
import styles from "./ImageLinkBlock.module.css";
import { useRef, useState } from "react";
import { imageBuilder } from "../../sanity/lib/image";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);

const VIEWPORT_WIDTHS = [320, 360, 375, 414, 768, 1024, 1240, 1366, 1440, 1728, 1860, 1920, 2480];

export const ImageLinkBlock = ({
  image,
  url,
  title,
  alignment,
}: {
  image: string;
  url: string;
  title: string;
  alignment: "left" | "right";
}) => {
  const [tooltipMode, setTooltipMode] = useState<
    /* Follow the cursor around */
    | "cursor"
    /* Hang below the image (primarily for keyboard users) */
    | "below"
  >("below");

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const loading = useLoadingContext();

  return (
    <div className={cx("container")} data-alignment={alignment}>
      <Link
        className={cx("link")}
        href={url}
        onFocus={() => setTooltipMode("below")}
        onMouseEnter={() => setTooltipMode("cursor")}
        onMouseMove={(event) => {
          const cellElement = event.currentTarget;
          const tooltipElement = tooltipRef.current;

          requestAnimationFrame(() => {
            const cellRect = cellElement.getBoundingClientRect();

            const left = event.clientX - cellRect.left;
            const top = event.clientY - cellRect.top;
            tooltipElement?.style.setProperty("transform", `translate3d(${left}px, ${top}px, 0)`);
          });
        }}
      >
        <InclusivelyHidden as="span">{title}</InclusivelyHidden>
        <picture>
          <img
            className={cx("image")}
            srcSet={VIEWPORT_WIDTHS.map((width) => {
              const src = imageBuilder
                .image(image)
                .format("webp")
                .width(width)
                .maxHeight(width) // Width fit=clip this ensures that portrait images are clipped to square aspect-ratio
                .fit("clip")
                .quality(90);

              return `${src} ${width}w`;
            }).join(",\n")}
            sizes="min(1240px, 100vw)"
            loading={loading}
            alt=""
          />
        </picture>
        <div ref={tooltipRef} className={cx("tooltip")} data-mode={tooltipMode}>
          {title}
        </div>
      </Link>
    </div>
  );
};
