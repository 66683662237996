import classNamesBind from "classnames/bind";
import classNames from "classnames";
import styles from "./InclusivelyHidden.module.css";

const cx = classNamesBind.bind(styles);

export const InclusivelyHidden = ({
  children,
  as: Tag,
  role,
  className,
}: {
  children: React.ReactNode;
  as: keyof HTMLElementTagNameMap;
  role?: string;
  className?: string;
}) => (
  <Tag className={classNames(className, cx("inclusively-hidden"))} role={role}>
    {children}
  </Tag>
);
