"use client";

import dynamic from "next/dynamic";
import type { MuxVideoType } from "../../api/muxVideoSchema";
import classNames from "classnames/bind";
import { imageBuilder } from "../../sanity/lib/image";
import styles from "./VideoBlock.module.css";
import { hasCookieConsent } from "../../helpers/cookieConsent";
import { useEffect, useState } from "react";

const cx = classNames.bind(styles);

const MuxVideo = dynamic(() => import("../MuxVideo/MuxVideo").then((mod) => mod.MuxVideo));

const DURATION_THRESHOLD = 45;

/*
// KEEPING THIS OLD CODE HERE AS IT COULD BE USEFUL

// A little overkil, but ensures poster image and video player are same size via CSS
function convertAspectRatioToCSSVariable(aspectRatio: string | undefined): string | undefined {
  if (!aspectRatio) {
    return undefined; // Handle null or undefined values
  }

  const [width, height] = aspectRatio.split(":").map(Number);
  if (isNaN(width) || isNaN(height) || height === 0) {
    return undefined; // Invalid aspect ratio
  }

  // convert "16:9" -> "9 / 16"
  return `${height} / ${width}`;
}
*/

export function VideoBlock({
  url,
  mediaType,
  image,
  videoMux,
  autoplay,
  noCookiesText,
}: {
  url?: string;
  mediaType: string;
  image?: string;
  videoMux?: MuxVideoType;
  autoplay?: boolean;
  noCookiesText: string;
}) {
  // const aspectRatio = convertAspectRatioToCSSVariable(videoMux?.data?.aspect_ratio ?? undefined);

  const [consentGiven, setConsentGiven] = useState<boolean | null>(null);

  useEffect(() => {
    const updateConsent = () => {
      setConsentGiven(hasCookieConsent("statistics"));
    };

    // Initial check
    updateConsent();

    if (typeof window !== "undefined" && window.addEventListener) {
      window.addEventListener("CookiebotOnLoad", updateConsent);
    }

    // Clean up event listener on component unmount
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("CookiebotOnLoad", updateConsent);
      }
    };
  }, []);

  if (!consentGiven) {
    return (
      <div className={cx("container")}>
        <div className={cx("no-cookies")}>
          <p className={cx("no-cookies-text")}>{noCookiesText}</p>
          <svg
            className={cx("no-cookies-icon")}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className={cx("container")}>
      <div
        className={cx("video-wrapper")}
        // style={{ "--aspect-ratio": aspectRatio } as React.CSSProperties}
      >
        {mediaType === "embed" && url && (
          <iframe className={cx("iframe")} frameBorder="0" src={url}></iframe>
        )}
        {mediaType === "video" && videoMux && (
          <MuxVideo
            className={cx("video")}
            poster={image ? imageBuilder.image(image).url() : ""}
            playbackId={videoMux.playbackId}
            loop={autoplay && videoMux.data?.duration < DURATION_THRESHOLD ? true : false}
            autoplay={autoplay ? true : false}
            controls={true}
          />
        )}
      </div>
    </div>
  );
}
