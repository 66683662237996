"use client";

import classNames from "classnames/bind";
import { imageBuilder } from "../../sanity/lib/image";
import styles from "./StandaloneImageBlock.module.css";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);

const VIEWPORT_WIDTHS = [
  320, 360, 375, 414, 768, 1024, 1200, 1280, 1366, 1440, 1728, 1800, 1920, 2400, 2592,
];

export const StandaloneImageBlock = ({
  layout,
  image,
}: {
  layout: "full-bleed" | "constrained";
  image: string;
}) => {
  const loading = useLoadingContext();
  return (
    <div className={cx("container")}>
      <img
        className={cx("image")}
        srcSet={VIEWPORT_WIDTHS.map((width) => {
          const src = imageBuilder.image(image).format("webp").width(width).quality(90);

          return `${src} ${width}w`;
        }).join(",\n")}
        sizes={
          {
            "full-bleed": "min(1728px, 100vw)",
            constrained: "min(1200px, 100vw)",
          }[layout]
        }
        alt=""
        loading={loading}
        data-layout={layout}
      />
    </div>
  );
};
