"use client";

import { useRef, useState, useEffect, useCallback } from "react";
import classNames from "classnames/bind";
import styles from "./ImageGalleryBlock.module.css";
import { imageBuilder } from "../../sanity/lib/image";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);

const OPTIONS: EmblaOptionsType = { align: "start", loop: true, watchDrag: true };

/**
 * Translations:
 * carouselRoleDescription = Aria Role Description for announcing the region "carousel".
 * carouselLabel = Aria Label/Title of the carousel "images".
 * controlsLabel = Aria Label/Title of the grouped region containing prev/next buttons and navigation dots "slide controls".
 * slideRoleDescription = Aria Role Description for annoucing the grouped region as "slide".
 * slideLabel = Aria/Title of the slide, either "Slide 1 of 6" or override a title.
 * previousLabel = "Previous" button label.
 * nextLabel = "Next" button label.
 */

const PrevIcon = () => (
  <svg
    aria-hidden={true}
    className={cx("button-arrow")}
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 5.98387L17 8.01613L3.85946 8.01613L8.54595 12.6L7.1446 14L2.51733e-06 7.02258L7.1446 -2.76894e-06L8.54595 1.4L3.88243 5.98387L17 5.98387Z"
      fill="currentColor"
    />
  </svg>
);

const NextIcon = () => (
  <svg
    aria-hidden={true}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.499999 8.01613L0.499999 5.98387L13.6405 5.98387L8.95405 1.4L10.3554 1.72317e-06L17.5 6.97742L10.3554 14L8.95405 12.6L13.6176 8.01613L0.499999 8.01613Z"
      fill="currentColor"
    />
  </svg>
);

export const ImageGalleryBlock = ({
  items,
  carouselRoleDescription,
  carouselLabel,
  controlsLabel,
  previousLabel = "Previous",
  nextLabel = "Next",
  slideRoleDescription,
  slideLabel,
}: {
  previousLabel: string;
  nextLabel: string;
  carouselRoleDescription: string;
  carouselLabel: string;
  controlsLabel: string;
  slideRoleDescription: string;
  slideLabel: string;
  items: {
    imageUrl: string;
    imageAlt?: string;
    title: string;
  }[];
}) => {
  // Embla Carousel
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  // Embla Next/Prev buttons
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  // Dot navigation
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  // For "visual only" CSS `cursor` logic
  const [prevOrNext, setPrevOrNext] = useState<"next" | "prev" | "default">("default");
  const hoverLabelRef = useRef<HTMLDivElement | null>(null);

  // Embla Prev/Next buttons
  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);
  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  // Embla Dot Naivation
  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  // Trigger next/prev buttons for "visual only" cursor navigation
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const cellElement = event.currentTarget;
    const cellRect = cellElement.getBoundingClientRect();
    const left = event.clientX - cellRect.left;
    const width = cellRect.width;
    const widthPercent = (left / width) * 100;

    if (widthPercent < 50) {
      onPrevButtonClick();
    } else if (widthPercent >= 50) {
      onNextButtonClick();
    }
  };

  // Animate next/prev text to follow cursor for "visual only" navigation
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const cellElement = event.currentTarget;
    const hoverLabelElement = hoverLabelRef.current;

    requestAnimationFrame(() => {
      const cellRect = cellElement.getBoundingClientRect();

      const left = event.clientX - cellRect.left;
      const top = event.clientY - cellRect.top;
      const width = cellRect.width;
      const widthPercent = (left / width) * 100;

      if (widthPercent < 50) {
        hoverLabelElement?.style.setProperty("--prev-opacity", "1");
        hoverLabelElement?.style.setProperty("--next-opacity", "0");
        setPrevOrNext("prev");
      } else if (widthPercent >= 50) {
        hoverLabelElement?.style.setProperty("--prev-opacity", "0");
        hoverLabelElement?.style.setProperty("--next-opacity", "1");
        setPrevOrNext("next");
      } else {
        hoverLabelElement?.style.setProperty("--prev-opacity", "0");
        hoverLabelElement?.style.setProperty("--next-opacity", "0");
        setPrevOrNext("default");
      }

      hoverLabelElement?.style.setProperty("--left", `${left}px`);
      hoverLabelElement?.style.setProperty("--top", `${top}px`);
    });
  };

  const loading = useLoadingContext();

  return (
    <div
      className={cx("carousel")}
      role="region"
      aria-roledescription={carouselRoleDescription}
      aria-label={carouselLabel}
    >
      <div className={cx("controls")} role="group" aria-label={controlsLabel}>
        <div className={cx("control-buttons")}>
          <button
            className={cx("button", "button--prev")}
            type="button"
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          >
            <PrevIcon />
            <span>{previousLabel}</span>
          </button>
          <button
            className={cx("button", "button--next")}
            type="button"
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          >
            <span>{nextLabel}</span>
            <NextIcon />
          </button>
        </div>
        <div className={cx("navigation-dots")}>
          {scrollSnaps.map((_, index) => (
            <button
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={cx("navigation-dot")}
              data-selected={index === selectedIndex ? "true" : undefined}
            >
              <span
                className={cx("navigation-dot-label")}
              >{`Go to card ${index + 1} of ${scrollSnaps.length}`}</span>
            </button>
          ))}
        </div>
      </div>
      <div
        className={cx("hover-area")}
        data-prev-or-next={prevOrNext}
        data-prev-disabled={prevBtnDisabled ? true : undefined}
        data-next-disabled={nextBtnDisabled ? true : undefined}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
      >
        {/* This is a visual only label for mouse control, not keyboard accessible */}
        <span aria-hidden="true" className={cx("hover-label")} ref={hoverLabelRef}>
          <span className={cx("hover-label__button", "hover-label__button--prev")}>
            <PrevIcon />
            <span>{previousLabel}</span>
          </span>
          <span className={cx("hover-label__button", "hover-label__button--next")}>
            <span>{nextLabel}</span>
            <NextIcon />
          </span>
        </span>
        <div className={cx("viewport")} ref={emblaRef}>
          <div className={cx("slide-container")}>
            {items.map((item, index) => (
              <div
                className={cx("slide")}
                key={index}
                role="group"
                aria-roledescription={slideRoleDescription}
                aria-label={
                  item.title ??
                  slideLabel
                    .replace("{{count}}", `${index}`)
                    .replace("{{total}}", `${items.length}`)
                }
              >
                <img
                  className={cx("image")}
                  sizes="min(100vw, 1200px)"
                  srcSet={[320, 360, 375, 414, 768, 1024, 1200, 1280, 1366, 1440, 1728, 1920, 2400]
                    .map((width) => {
                      const src = imageBuilder
                        .image(item.imageUrl)
                        /**
                         * Using `.ignoreImageParams()` because otherwise using
                         * `width` and `height` together results in an erroneous
                         * `rect` property that breaks the "max" fit-mode.
                         *
                         * https://github.com/sanity-io/image-url/issues/32
                         */
                        .ignoreImageParams()
                        .format("webp")
                        .width(width)
                        .height(Math.round(width * (9 / 16)))
                        .fit("max")
                        .quality(90);

                      return `${src} ${width}w`;
                    })
                    .join(",\n")}
                  src={item.imageUrl}
                  loading={loading}
                  alt={item.imageAlt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
