import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    /**
     * This should be non-zero on production (but zero on staging and local
     * environments) to improve page-load performance, by caching all
     * server-side requests to external data sources like Sanity.
     *
     * Doesn't really need to be a far-future value - use of the Sanity CDN on
     * production should ensure that Sanity queries are fast and fresh-ish, so
     * this cache really just protects us from big overage costs under high
     * traffic load. Approx 5 minutes should be fine.
     *
     * Unit: seconds.
     */
    FETCH_CACHE_DURATION: z.string().min(1).pipe(z.coerce.number()),
    /**
     * This should be enabled on production (but not on staging and local
     * environments) to improve page-load performance, by reducing the duration
     * of API queries, at the cost of up-to-date responses.
     *
     * Invalidation:
     * The entire cache for a dataset is invalidated whenever there is a write
     * to a non-draft document in the dataset. Invalidations may take up to 60
     * seconds to fully propagate.
     *
     * See docs for more info: https://www.sanity.io/docs/api-cdn
     */
    USE_SANITY_CDN: z.enum(["true", "false"]).transform((input) => input === "true"),
    /**
     *
     */
    ROBOTS_INDEX_ENABLED: z.enum(["true", "false"]).transform((input) => input === "true"),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    /**
     * Find this at https://www.sanity.io/manage
     */
    NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID: z.string().min(1),
    /**
     * Find this at https://www.sanity.io/manage
     */
    NEXT_PUBLIC_SANITY_STUDIO_DATASET: z.string().min(1),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    ROBOTS_INDEX_ENABLED: process.env.ROBOTS_INDEX_ENABLED,
    FETCH_CACHE_DURATION: process.env.FETCH_CACHE_DURATION,
    USE_SANITY_CDN: process.env.USE_SANITY_CDN,
    NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID,
    NEXT_PUBLIC_SANITY_STUDIO_DATASET: process.env.NEXT_PUBLIC_SANITY_STUDIO_DATASET,
  },
});
