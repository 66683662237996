"use client";

import { ProviderProps, createContext, useContext } from "react";

const LoadingContext = createContext<"eager" | "lazy">("eager");

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingContextProvider = (props: ProviderProps<"eager" | "lazy">) => (
  <LoadingContext.Provider {...props} />
);
