"use client";

import classNames from "classnames/bind";
import { imageBuilder } from "../../sanity/lib/image";
import { ProjectSticker } from "../ProjectSticker/ProjectSticker";
import { InclusivelyHidden } from "../InclusivelyHidden/InclusivelyHidden";
import Link from "next/link";
import styles from "./ProjectGalleryMobile.module.css";
import { useLoadingContext } from "../../LoadingContext";
import useEmblaCarousel from "embla-carousel-react";
import { useRef, useEffect, useState, useCallback, CSSProperties } from "react";

const cx = classNames.bind(styles);

type Item = {
  imageUrl: string;
  imageAlt?: string;
  stickerText?: string;
  stickerPosition?: "bottom-left" | "bottom-right" | "top-left" | "top-right";
  title: string;
  href: string;
};

const Slide = ({ item }: { item: Item }) => {
  const [tooltipMode, setTooltipMode] = useState<
    /* Follow the cursor around */
    | "cursor"
    /* Hang below the image (primarily for keyboard users) */
    | "below"
  >("below");

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const loading = useLoadingContext();

  return (
    <div
      className={cx("item")}
      onFocus={() => setTooltipMode("below")}
      onMouseEnter={() => setTooltipMode("cursor")}
      onMouseMove={(event) => {
        const cellElement = event.currentTarget;
        const tooltipElement = tooltipRef.current;

        requestAnimationFrame(() => {
          const cellRect = cellElement.getBoundingClientRect();

          const left = event.clientX - cellRect.left;
          const top = event.clientY - cellRect.top;
          tooltipElement?.style.setProperty("--transform", `translate3d(${left}px, ${top}px, 0)`);
        });
      }}
    >
      <Link className={cx("link")} href={item.href}>
        <InclusivelyHidden as="h3">{item.title}</InclusivelyHidden>
      </Link>
      <img
        className={cx("image")}
        // To be kept in-sync with `width` of `.item` in stylesheet.
        sizes="(min-width: 768px) min(42vw, 740px), 80vw"
        srcSet={[320, 360, 375, 414, 740, 768, 1024, 1280, 1366, 1440, 1480, 1728, 1920]
          .map((width) => {
            const src = imageBuilder.image(item.imageUrl).format("webp").width(width).quality(90);

            return `${src} ${width}w`;
          })
          .join(",\n")}
        src={item.imageUrl}
        loading={loading}
        alt={item.imageAlt}
      />
      {item.stickerText && (
        <div className={cx("sticker")} data-align={item.stickerPosition ?? "bottom-right"}>
          <ProjectSticker>{item.stickerText}</ProjectSticker>
        </div>
      )}
      <div
        /**
         * Deliberately not marking this up as role=tooltip, because the content
         * is redundant - it's a duplicate of the InclusivelyHidden `h3` content
         * above. This tooltip provides purely aesthetic functionality for
         * sighted users only.
         */
        aria-hidden="true"
        ref={tooltipRef}
        className={cx("tooltip")}
        data-mode={tooltipMode}
      >
        {item.title}
      </div>
    </div>
  );
};

export const ProjectGalleryMobile = ({ title, items }: { title?: string; items: Item[] }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  // const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  // const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    containScroll: "trimSnaps",
    dragFree: false,
    align: "start",
  });

  // const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  // const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;

    setSelectedIndex(emblaApi.selectedScrollSnap());
    // setPreviousIndex(emblaApi.previousScrollSnap());
    // setPrevBtnEnabled(emblaApi.canScrollPrev());
    // setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);

    return () => {
      emblaApi.off("reInit", onSelect);
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <div className={cx("container")}>
      {title && <h2 className={cx("headline")}>{title}</h2>}

      <div className={cx("embla__viewport")} ref={emblaRef}>
        <div className={cx("embla__container")}>
          {items.map((item, index) => (
            <Slide key={index} item={item} />
          ))}
        </div>
      </div>
      <div className={cx("footer")}>
        <div className={cx("progress")}>
          <div
            className={cx("progress__bar")}
            style={
              {
                "--_index": selectedIndex + 1,
                "--_length": scrollSnaps.length,
              } as CSSProperties
            }
          />
        </div>
      </div>
    </div>
  );
};
