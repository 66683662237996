"use client";

import classNames from "classnames/bind";
import { imageBuilder } from "../../sanity/lib/image";
import styles from "./SideBySideImageBlock.module.css";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);

const VIEWPORT_WIDTHS = [320, 360, 375, 414, 768, 1024, 1280, 1366, 1440, 1728, 1920];

export const SideBySideImageBlock = ({
  layout,
  leftImage,
  rightImage,
}: {
  layout: "offset" | "top-aligned";
  leftImage: string;
  rightImage: string;
}) => {
  const loading = useLoadingContext();
  return (
    <div className={cx("container")}>
      <div className={cx("grid")} data-layout={layout}>
        {[leftImage, rightImage].map((imageUrl, index) => (
          <div key={index} className={cx("column")}>
            <img
              className={cx("image")}
              srcSet={VIEWPORT_WIDTHS.map((width) => {
                const src = imageBuilder.image(imageUrl).format("webp").width(width).quality(90);

                return `${src} ${width}w`;
              }).join(",\n")}
              sizes="(min-width: 768px) min(842px, 50vw), 100vw"
              loading={loading}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};
