"use client";

import React from "react";
import classNames from "classnames/bind";
import styles from "./StageImage.module.css";
import { imageBuilder } from "../../sanity/lib/image";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);

const VIEWPORT_WIDTHS = [
  320, 360, 375, 414, 768, 828 /* 414 × 2 */, 1024, 1200, 1440, 1800, 1920, 2400,
];

export function StageImage({ imageUrl, alt }: { imageUrl: string; alt: string }) {
  const loading = useLoadingContext();
  return (
    <div className={cx("container")}>
      <img
        alt={alt}
        className={cx("image")}
        src={imageUrl}
        srcSet={VIEWPORT_WIDTHS.map((width) => {
          const src = imageBuilder.image(imageUrl).format("webp").width(width).quality(90);

          return `${src} ${width}w`;
        }).join(",\n")}
        sizes="min(1200px, 100vw)"
        loading={loading}
      />
    </div>
  );
}
