"use client";

import classNames from "classnames/bind";
import { Heading } from "../Heading/Heading";
import { imageBuilder } from "../../sanity/lib/image";
import styles from "./TeamGallery.module.css";
import { useLoadingContext } from "../../LoadingContext";

const cx = classNames.bind(styles);
const VIEWPORT_WIDTHS = [320, 360, 375, 414, 648];

export const TeamGallery = ({
  title,
  members,
}: {
  title: string;
  members: { name: string; qualifications?: string; imageUrl?: string }[];
}) => {
  const loading = useLoadingContext();
  return (
    <div className={cx("container")}>
      <Heading as="h2" className={cx("heading")}>
        {title}
      </Heading>
      <div className={cx("members")}>
        {members.map((member, index) => (
          <div key={index} className={cx("member")}>
            <div className={cx("content")}>
              <h3 className={cx("name")}>{member.name}</h3>
              {member.qualifications && (
                <p className={cx("qualifications")}>{member.qualifications}</p>
              )}
            </div>
            <div className={cx("image-container")}>
              {member.imageUrl && (
                <img
                  className={cx("image")}
                  src={member.imageUrl}
                  srcSet={VIEWPORT_WIDTHS.map((width) => {
                    const src = imageBuilder
                      .image(member.imageUrl ?? "")
                      .format("webp")
                      .width(width)
                      .height(width)
                      .fit("fill")
                      .quality(90);

                    return `${src} ${width}w`;
                  }).join(",\n")}
                  sizes="min(324px, 20vw)"
                  alt=""
                  loading={loading}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
