import classnames from "classnames/bind";
import combineClassnames from "classnames";
import styles from "./Heading.module.css";

const cx = classnames.bind(styles);

export const Heading = ({
  as: Tag,
  className,
  children,
}: {
  as: keyof HTMLElementTagNameMap;
  className?: string;
  children: React.ReactNode;
}) => {
  return <Tag className={combineClassnames(className, cx("heading"))}>{children}</Tag>;
};
