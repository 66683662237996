"use client";

import classNames from "classnames/bind";
import styles from "./Quote.module.css";
import { useEffect, useRef, useState } from "react";

const cx = classNames.bind(styles);

const clamp = (min: number, val: number, max: number) => Math.max(min, Math.min(val, max));

// https://easings.net/
function easeInQuad(x: number): number {
  return x * x;
}

export function Quote({
  children,
  backgroundColor,
}: {
  children: string;
  backgroundColor: string;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const backgroundRef = useRef<HTMLDivElement | null>(null);
  const circleRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLQuoteElement | null>(null);
  const [isCircleVisible, setIsCircleVisible] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    const containerElement = containerRef.current;
    const backgroundElement = backgroundRef.current;
    const circleElement = circleRef.current;
    const contentElement = contentRef.current;

    if (!containerElement || !backgroundElement || !circleElement || !contentElement) {
      throw new Error("Couldn't initialise scroll transitions, required elements not present.");
    }

    const onScroll = () => {
      requestAnimationFrame(() => {
        if (containerElement) {
          const containerRect = containerElement.getBoundingClientRect();

          /**
           * `0` when the top of the element enters the bottom of the viewport.
           * `1` when the top of the element leaves the top of the viewport.
           */
          const scrollYProgress = -(containerRect.top - window.innerHeight) / containerRect.height;

          backgroundElement.style.setProperty(
            "opacity",
            clamp(
              0,
              scrollYProgress < 1
                ? // Fade-in as the container is scrolled into view:
                  scrollYProgress
                : // Fade-out as the container is scrolled out of view:
                  2 - scrollYProgress,
              1,
            ).toString(),
          );

          if (!isCircleVisible) {
            /**
             * proportionally set the opacity from 0 - 1, when viewport is between 0.125 and 0.25,
             * and clamp the value between 0 and 1
             */
            if (scrollYProgress <= 0.5) {
              const clampedValue = Math.min(
                Math.max((scrollYProgress - 0.125) / (0.5 - 0.125), 0),
                1,
              );
              circleElement.style.setProperty("opacity", clampedValue.toString());
            }
            /**
             * Once one-half of the container has been scrolled into view, reveal
             * the circle (permanently).
             */
            if (scrollYProgress > 0.5) {
              circleElement.style.setProperty("opacity", "1");
              setIsCircleVisible(true);
            }
          }

          if (!isContentVisible) {
            /**
             * proportionally set the opacity from 0 - 1, when viewport is between 0.5 and 0.75,
             * and clamp the value between 0 and 1, and apply an easing modifier.
             */
            if (scrollYProgress <= 0.75) {
              const clampedValue = Math.min(Math.max((scrollYProgress - 0.5) / (0.75 - 0.5), 0), 1);
              contentElement.style.setProperty("opacity", easeInQuad(clampedValue).toString());
            }

            /**
             * Once the whole container has been scrolled into view, reveal the
             * content (permanently).
             */
            if (scrollYProgress > 0.75) {
              contentElement.style.setProperty("opacity", "1");
              setIsContentVisible(true);
            }
          }
        }
      });
    };

    // Intialise on first-render:
    onScroll();

    /**
     * Workaround for a race-conditions issue. Sometimes the background would
     * initialize as green after navigation because some images were yet to load
     * which would bump the quote down. Ie. content-layout-shifts cause the
     * quote to move down the page.
     */
    const bodyResizeObserver = new ResizeObserver(() => onScroll());

    bodyResizeObserver.observe(document.body);

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
      bodyResizeObserver.disconnect();
    };
  }, [isContentVisible, isCircleVisible]);

  return (
    <div className={cx("container")} ref={containerRef}>
      <div className={cx("background")} ref={backgroundRef} style={{ backgroundColor }} />
      <div className={cx("inner")}>
        <div className={cx("circle")} ref={circleRef} />
        <blockquote className={cx("content")} ref={contentRef}>
          <p>{children}</p>
        </blockquote>
      </div>
    </div>
  );
}
